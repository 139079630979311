<template>
  <div>
    <div class="field">
      <label for="cardNumber" class="label">Número do cartão</label>
      <div class="fields">
        <div class="eight wide field">
          <input type="text" id="cardNumber" name="card[number]" class="ui input disabled" disabled :value="cardNumber">
        </div>
        <div class="four wide field">
          <input type="text" id="cardCvc" name="card[cvc]" class="ui input disabled" disabled maxlength="3" placeholder="CVC" value="***">
        </div>
      </div>
    </div>

    <div class="field">
      <label for="cardExpiration" class="label">Vencimento</label>
      <input type="text" id="cardExpiration" name="card[expiration]" class="ui input disabled" disabled placeholder="expiration" :value="card.expiration">
    </div>

    <div class="field">
      <label for="cardBrand" class="label">Bandeira</label>
      <input type="text" id="cardBrand" name="card[brand]" class="ui input disabled" disabled :value="card.cardBrandName" />
    </div>

    <div class="field">
      <label for="cardHolderName" class="label">Portador</label>
      <input type="text" id="cardHolderName" name="card[holderName]" class="ui input disabled" disabled :value="card.holderName">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TFormCreditCard',
  data () {
    return {
      months: [
        {value: 1, name: "Janeiro"},
        {value: 2, name: "Fevereiro"},
        {value: 3, name: "Março"},
        {value: 4, name: "Abril"},
        {value: 5, name: "Maio"},
        {value: 6, name: "Junho"},
        {value: 7, name: "Julho"},
        {value: 8, name: "Agosto"},
        {value: 9, name: "Setembro"},
        {value: 10, name: "Outubro"},
        {value: 11, name: "Novembro"},
        {value: 12, name: "Dezembro"}
      ]
    }
  },
  computed: {
    ...mapState({
      donation: ({ donations }) => donations.donationDetail,
      customer: ({ donations }) => donations.donationDetail.customer,
      cardInfo: ({ donations }) => donations.donationDetail.cardInfo,
      card: ({ donations }) => donations.donationDetail.card,
    }),
    cardNumber: ({ card }) => {
      let firstPart = card.firstDigits.substr(0, 4)
      let secondPart = card.firstDigits.substr(3, 2)
      return `${firstPart}.${secondPart}**.****.${card.lastDigits}`
    }
  }
}
</script>
